html,
body {
  color: #636b6f;
  font-family: "Work Sans", sans-serif;
}

.links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.col {
  margin-top: 80px;
}

.col h1 {
  font-weight: 800;
  padding-bottom: 10px;
  color: #23272a;
}

.col p {
  font-size: 17px;
  color: black;
}

.col .col-md-8 {
  background-color: #f2f2f2;
  padding: 40px;
}

.backlink p {
  font-size: 16px;
}

.backlink {
  margin-top: 30px;
}
