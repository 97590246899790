@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Rubik&display=swap");

body,
html {
  font-family: "Poppins", sans-serif !important;
  scroll-behavior: smooth !important;
  width: 100%;
  overflow-x: hidden;
}

::placeholder {
  padding-left: 5px;
}
.nav-btn {
  background: #ffffff;
  border-radius: 5px;
  width: 120px;
  font-family: "Poppins";
  height: 49px;
  color: #044188;
  border: 1px solid #044188;
}

#navbar {
  height: 70px;
  padding: 30px;
  margin-top: 10px !important;
  background: #fcfeff;
}
.nav-dropdown {
  margin-left: 20px;
  margin-right: 30px;
}
.dropdown-toggle {
  font-family: "Poppins";
  /* Add any other font-related styles here */
}
a.btn.btn-primary {
  background-color: #044188;
  margin-right: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: "Poppins";
}
a.btn {
  font-size: 10px;
}
button.navbar-toggler {
  border: none;
}

#nav-dropdown2 {
  color: rgb(255, 255, 255) !important;
  margin-left: 20px;
  text-decoration: none;
  margin-right: 20px;
  font-weight: 400;
  font-family: "Poppins";
}
.dropdown-menu.show {
  white-space: normal;
}
.navlink2 {
}
.logo {
  width: 200px;
}
.carosel-card {
  display: flex;
  margin-left: 50px;
  height: 400px;
  width: 100%;
  justify-content: space-evenly;
}
.carosel-text {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.carosel-text h1 {
  color: #044188;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
}

.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 60%;
  background: rgba(0, 0, 0, 0.2);
  border: 0.5px solid #3e3f40;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.carousel .carousel-indicators .active {
  background-color: #044188;
}

.carosel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin-bottom: 90px;
  width: 100%;
}
.carosel-img {
  width: 50%;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.arrow {
  height: 400px;
}

.feather-img,
.compass-img {
  width: 350px;
  border-radius: 5px;
  cursor: pointer;
}
.about-card {
  background-color: #044188;
  width: 300px;
  align-items: center;
  border-radius: 5px;
  height: 210px;
  top: -75px;
  left: 170px;
  position: relative;
  font-family: Poppins;
  padding: 20px;
  font-size: 14px;
  color: white;
  font-weight: 400;
  line-height: 23px;
}
.more-img {
  width: 20px;
}
.about-card-holder {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  align-items: center;
  width: 90%;
}

.about-text {
  text-align: center;
  color: #404040;
}
.about-text p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.about-text h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 59px;
}
.about-container {
  margin-left: 50px;
  margin-top: 20px;
  margin-right: 50px;
}
.attribution-card {
  background-color: #ffff;
  width: 23%;
  border-radius: 5px;
  padding: 20px;
  height: 100%;
}
.attribution-card p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.attribution-container h4 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 60px;
}
.attribution-container h3 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  padding-top: 10px;
}
.attribution-container {
  height: 80vh;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}
.attribution-card-holder {
  display: flex;
  width: 100%;
  margin-top: 100px;
  justify-content: space-evenly;
  align-items: center;
}
/* service component */
.service-container {
  margin-bottom: 100px;
}
.service-img-holder {
  cursor: pointer;
  width: fit-content;
}

.service-text {
  text-align: center;
  padding-top: 20px;
  color: #404040;
}
.service-text h4 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
}
.service-text p {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: normal;
  text-align: center;
}

.service-img {
  width: 250px !important;
  height: 200px !important;
  cursor: pointer;
  transition: 1s;
  border-radius: 5px;
  background-repeat: no-repeat;
  color: #ffff;
}
.service-img h4 {
  position: relative;
  bottom: 80px !important;
}
.get-in-touch {
  color: #ffff;
  font-family: Poppins;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  animation: move 5s ease infinite;
}
.get-in-touch h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 75px;
}
.get-in-touch-btn {
  width: 150px;
  background-color: #044188;
  border: none;
  color: #ffff;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  transition: 0.3s;
}
.get-in-touch-btn:hover {
  opacity: 1;
  color: white;
  background-color: #83460f;
}
/* .line {
  background: #044188;
  height: 16px;
  position: relative;
  top: 90px;
  z-index: 1;
  border-radius: 10px;
} */
.service-img h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  position: relative;
  bottom: 120px;
  padding: 20px;
  color: #ffffff;
}
.service-img h4:hover {
  color: #044188;
}
.service-img img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.service-img img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.footer1-question-img {
  width: 370px;
  height: 300px;
  padding: 10px;
}
.footer1-card {
  display: flex;
  background-color: #ffff;
  width: 370px;
  height: 300px;
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  box-shadow: #044188 0px 1px 2px 0px;
  border-radius: 5px;
}
.footer1-img {
  margin-right: 10px;
  width: 50px;
  cursor: pointer;
}
.footer1-card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.footer1-card h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}
.footer1-container {
  margin-bottom: 100px;
}
.footer1-card-holder {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
}
.footer1-bg-img img {
  width: 390px;
  box-shadow: #044188 0px 1px 2px 0px;
  height: 300px;
  border-radius: 5px;
}
.footer2 {
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.footer2-cards {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
}

.footer2-card1 form {
  display: flex;
  padding-left: 50px;
  width: 100;
  flex-direction: column;
}
.footer2-card1 form h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #1d1d1f;
}
.footer2-form-btn {
  width: 100px;
  background-color: #044188;
  border: none;
  color: #ffff;
  height: 50px;
  bottom: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
}
.footer2-card1 input {
  width: 75%;
  outline: none;
  background-color: #f8fbfd;
  border: 1px solid #404040;
  margin-bottom: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #404040;
}
.footer2-card1 {
  width: 40%;
}
.footer2-card2 {
  width: 60%;
  display: flex;
}
.footer2-card2 h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #1d1d1f;
}
.footer2-card3 {
  animation: rotateY-anim 3s linear infinite;
}
@keyframes rotateY-anim {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}
.links {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #1d1d1f;
}
.links:hover {
  outline: none;
  text-decoration: none;
  color: #1d1d1f;
}
.footer3-holder {
  padding: 50px;
  display: flex;
  height: 400px;
  justify-content: space-evenly;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
}
.footer3-holder h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #1d1d1f;
}
.footer3-link {
  text-decoration: none;
  color: #044188;
}
.footer3-holder p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #044188;
}
.footer3-card1 {
  width: 40%;
}
.footer3-card3 a {
  text-decoration: none;
}
.footer3-card-holder {
  width: 60%;
  display: flex;
  justify-content: space-around;
}
.footer-4 {
  display: none;
}
.footer4-cards {
  display: flex;
  align-items: center;
  height: 450px;
  justify-content: space-evenly;
}
.footer4-card1 {
  width: 20%;
  height: 330px;
}
.social-media-icon {
  display: flex;
}
.social-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.footer4-cards h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #044188;
}
.footer4-card2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: 330px;
}
.footer4-card3 {
  width: 30%;
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tweet-box {
  width: 70%;
  height: 330px;
  box-shadow: 12px 19px 19px rgba(0, 0, 0, 0.15);
  background-color: #ffff;
  border: 1px solid #044188;
  border-radius: 5px;
}
.subscribe-box {
  width: 70%;
  display: flex;
  flex-direction: column;
  height: 330px;
  padding: 10px;
  background: #f7faff;
  border: 1px solid #044188;
  border-radius: 5px;
}
.subscribe-box label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  text-transform: capitalize;
  color: #404040;
}
.subscribe-box input {
  width: 90%;
  outline: none;
  border: none;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 7px 7px 18px rgba(0, 0, 0, 0.15);
}
.subscribe-box p {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.subscribe-box-btn {
  width: 100px;
  height: 45px;
  border: none;
  background: linear-gradient(270deg, #83460f 24.27%, #d07f31 79.51%);
  border-radius: 7px;
  font-family: "Poppins";
  color: #ffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  transition: 0.3s;
}
.subscribe-box-btn:hover {
  opacity: 0.8;
  background: linear-gradient(270deg, #83460f 24.27%, #d07f31 79.51%);
}
.footer5-cards {
  background: linear-gradient(269.87deg, #043e7f 39.64%, #075cc7 81.18%);
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 5px;
  color: #ffff;
}
.footer5-cards p {
  font-size: 15px;
}
.footer5-cards h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
}
.footer-links {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: #ffffff;
}
.footer-links:hover {
  color: #ffffff;
}
.footer-links-holder {
  display: flex;
  width: 40%;
  justify-content: space-around;
}
/* about pages styling */
.about-page-header {
  color: #ffff;
  height: 150px;
  background-repeat: no-repeat;
  background-image: url(images/aboutheader.png);
  background-size: cover;
  text-align: center;
  background-size: cover;
}
.about-page-header h1,
.our-services h1,
.main-MF h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #ffff;
  letter-spacing: normal;
}
.main-MF {
  height: 150px;
  text-align: center;
}
.about-page-card1,
.about-page-card2 {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-evenly;
}
.about-page-card2-boxtext {
  display: flex;
  margin-bottom: 50px;
  margin-top: 50px;
  flex-direction: column;
  width: 50%;
}
.about-page-card1-text {
  width: 35%;
}
.about-page-card1-text h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 49px;
  color: #1d1d1f;
}
.about-page-card1-img {
  width: 30%;
  height: 270px;
  border-radius: 10px;
  cursor: pointer;

  display: flex;
  /* border: 1px solid grey; */
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.about-img {
  width: 405px;
  height: 270px;
  cursor: pointer;

  border-radius: 10px;
  transition: 1s;
}
.about-page-card1-img:hover img {
  transform: scale(1.3);
}
.about-page-card2-text {
  display: flex;
  height: 530px;
  width: 80%;
  margin-top: 30px;
  justify-content: space-between;
}
.about-page-card2-text p {
  font-size: 16px;
}
.about-page-card2-box {
  width: 35%;
}
.about-page-card2-box2 {
  width: 35%;
}
.about-page-card3 {
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: "Poppins";
  padding: 20px;
  /* margin-top: 50px; */
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  height: 150px;
  text-align: center;
  color: #ffffff;
}

.logo-box {
  display: flex;
  padding: 20px;
}
.logo-box h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}
.about-page-cards {
  margin-top: 30px;
}
.accordion-body {
  width: 100%;
}
.accordion1 {
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  display: flex;
}
.accordion:hover {
  outline: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  box-shadow: none;
  border: 1px solid black;
}
.accordion h4 {
  font-family: "Poppins";
  letter-spacing: 0.25%;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 59px;
  color: #1d1d1f;
}
.accordion-card {
  margin-bottom: 100px;
  margin-top: 50px;
}
button.accordion-button {
  background-color: #044188 !important;
  color: white;
  text-decoration: none;
  outline: none;
  border: none;
}

/* Team pages styling */
.team-page {
  width: 100%;
}
.profile-img {
  width: 210px;
  cursor: pointer;

  height: 230px;
  background-color: #fcfeff;
  border: none;
}

.profile-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 15px;
}
.profile-box button {
  text-decoration: none;
  outline: none;
}
.profile-box button:focus {
  text-decoration: none;
  outline: none;
  border: none;
}
.team-page-card1 {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-links {
  text-decoration: none;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #ffff;
}
.team-links2 {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #044188;
}

.modal-body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.team-links:hover {
  outline: none;
  text-decoration: none;
  color: #3650e4;
}
.team-page-btn1 {
  width: 273px;
  height: 55px;
  margin-right: 10px;
  color: #ffff;
  border: none;
  transition: 0.3s;
  text-align: center;
  background: #044188;
  box-shadow: -1px -1px 1px rgba(4, 65, 136, 0.2),
    1px 1px 1px rgba(4, 65, 136, 0.2);
  border-radius: 5px;
}
.team-page-btn1:hover {
}
.team-page-btn2 {
  width: 338px;
  height: 55px;
  transition: 0.3s;
  margin-left: 10px;
  color: #044188;
  background: #ffffff;
  border: 1px solid #044188;
  box-shadow: -1px -1px 1px rgba(4, 65, 136, 0.2),
    1px 1px 1px rgba(4, 65, 136, 0.2);
  border-radius: 5px;
}
.team-page-card2 {
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.team-page-card2 h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #044188;
}

/* contact page */
.contact-holder {
  width: 100%;
}
.contact-page {
  background-color: #fcfeff;
}
.info-text-holder {
  background-color: #044188;
  width: 80%;
  border-radius: 5px;
  padding: 20px;
  color: #ffff;
}
.contact-page-form {
  width: 50%;
  padding: 0px;
  margin: 0px;
}
.contact-page-info {
  width: 50%;
  padding: 0px;
  margin: 0px;
}
.contact-page-form form {
  display: flex;
  padding: 20px;
  font-size: 12px;
  border-radius: 5px;
  width: 80%;
  background-color: #044188;
  flex-direction: column;
}
.contact-page-form form h4,
.info-text-holder h4 {
  font-size: 18px;
  font-family: "Poppins";
}
.contact-page-form {
  color: #ffff;
}
.contact-page-form input {
  border-radius: 5px;
  margin-bottom: 20px;
  height: 40px;
  outline: none;
  background-color: #ffff;
  border: none;
}
.header4 {
  width: 50%;
  padding-left: 70px;
  font-size: 30px;
}
.contact-page-card1 {
  display: flex;
  font-family: "Poppins";
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  width: 100%;
}
textarea {
  width: 100%;
  border-radius: 5px;
  background-color: #ffff;
}
.contact-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  color: #044188;
  width: 120px;
  border: none;
  height: 35px;
  background: #ffffff;
  border-radius: 10px;
  transition: 1.5ms;
}
.contact-btn:hover {
  background-color: #044188;
  color: #ffffff;
  border: 1px solid white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.contact-page-info {
  display: flex;
  width: 50%;
  height: 400px;
}
.contact-page-info-text {
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  padding: 10px;
}
.contact-icon {
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
  width: 20%;
}

/* servicepage */
.service-card1 {
  display: flex;
}
.row {
  padding-left: 50px;
}
.service-info {
  background-color: #ffffff;
  width: 250px;
  height: 230px;
  border-radius: 5px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 1.95px 1.95px 2.6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1f;
}
.service-img-container {
  margin-bottom: 30px;
}

.service-img2 {
  width: 250px;
  height: 230px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 1.95px 1.95px 2.6px;
}
.service-img2 img {
  border-top-right-radius: 60px;
}
.service-grid h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 40px;
  color: #044188;
}
.service-grid {
  margin-bottom: 50px;
  margin-top: 30px !important;
  overflow: hidden;
}
.service-link {
  color: #044188;
  text-decoration: none;
}

.service-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  border: none;
  background-color: inherit;
  color: #044188;
  float: right;
  margin-top: 30px;
}
/* mutual fund */
.mutual-info {
  font-family: "Poppins";
  font-style: normal;
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  padding: 10px;
  line-height: 25px;
  color: #1d1d1f;
}
.sub-form {
  width: 25%;
  height: 40px;
  border: none;
  font-size: 12px;
  margin-top: 10px;
  color: #f7faff;
  border-radius: 5px;
  background-color: #044188;
}
.mutual-img-holder {
  margin-left: 55px;
}
.research-mutual-img-holder {
  margin-left: 30px;
}
.research-mutual-info {
  /* width: 60%; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 30px;
  line-height: 25px;
  color: #1d1d1f;
}
.research-mutual-img {
  height: 350px;
  width: 350px !important;
  border-radius: 5px;
}
.mutual-card1 {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  justify-content: space-evenly;
  padding-bottom: 10px;
}
.container1 {
  margin-left: 150px;
}
.mutual-cards {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  margin-top: 20px;

  color: #044188;
}
.mutual-img {
  height: 100%;
  width: 350px;
  cursor: pointer;
  border-radius: 5px;
  /* margin: 25px;
  transition: 1s; */
  background-repeat: no-repeat;
  color: #ffff;
}

/* .mutual-img-holder:hover img {
  transform: scale(1.1);
} */
.contact-link {
  width: 100px;
  background-color: #044188;
  border: none;
  color: #ffff;
  margin-bottom: 10px;
  margin-right: 10px;
  float: right;
  height: 40px;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
}
/* Portfolio */
.portfolio-link {
  width: 200px;
  background-color: #044188;
  border: none;
  color: #ffff;
  margin-bottom: 10px;
  margin-right: 10px;
  height: 35px;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
}
/* Report page */
.report-card1-text {
  background: linear-gradient(90deg, #044188 36.1%, #075cc7 64.02%);
  color: #ffffff;
  position: relative;
  top: 300px;
  text-decoration: none;
  width: 100%;
  border: none;
  height: 100px;
}

.report-card1-text h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;

  line-height: 30px;
  color: #ffffff;
}
.report-card1-text p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.report-img {
  width: 50%;
  cursor: pointer;
}
.report-card1-info-holder {
  width: 40%;
}
.report-card1-info {
  background: rgba(0, 0, 0, 0.05);
  height: 90px;
  margin: 10px;
}
.report-cards {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 100px;
  background-color: #ffff;
}
.report-card1 {
  display: flex;
  padding-top: 30px;

  justify-content: space-around;
}
.report-card1-info-holder h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #044188;
}
.report-card1-info-holder p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #1d1d1f;
}
.report-card2 {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 30px;
}
.report-card2-holder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  width: 60%;
  padding: 20px;
  font-size: 14px;
  line-height: 30px;
  color: #1d1d1f;
}
.report-card2-holder a {
  text-decoration: none;
  color: #1d1d1f;
}
.report-card2-holder a:hover {
  text-decoration: none;
}
/* report update */
.pdf-box {
  background-color: grey;
  height: 500px;
  margin: 800px;
  margin: 0px auto;
}
.report-icon {
}
.update-img {
  background: linear-gradient(90deg, #044188 26.46%, #075cc7 75.15%);
  border-radius: 12px;
  width: 220px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 250px;
}
.report-icon h4 {
  font-family: "Poppins";
  margin-top: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
}
.update-img h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 136.5%;
  color: #ffffff;
}
.update-img p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
}
.update-form {
  width: 80%;
  margin: 0 auto;

  height: 600px;
  margin-top: 100px;
  background-color: #044188;
}
.update-form form {
  display: flex;
  padding-left: 50px;
  flex-direction: column;
}
.update-form input {
  width: 90%;
  border: none;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 20px;
}
.update-form-text {
  padding-left: 50px;
}
.update-form-text h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 60px;
  color: #ffffff;
}
.update-form-text p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #ffffff;
}
.selectingcolor {
  color: rgb(4, 65, 136);
}

@media only screen and (max-width: 640px) {
  #navbar {
    padding: 0px;
    height: fit-content;
  }
  .logo {
    width: 110px;
  }
  .mobile-nav {
    background: rgba(4, 65, 136, 0.8);
    z-index: 1;
    padding: 0 1rem 1rem;
    text-align: left;
    border-radius: 10px;
  }
  a.dropdown-toggle.nav-link {
    color: rgb(255, 255, 255);
  }
  a.btn.btn-primary {
    margin-top: 5px;
    width: 100%;
    height: 40px;
  }
  .nav-btn {
    margin-top: 20px;
    width: 35%;
  }
  .carosel-container {
    height: 500px;
  }
  button.nav-btn {
    width: 100%;
  }
  .carosel-card {
    display: flex;
    margin-left: 0px;
  }
  .carosel-text h1 {
    color: #044188;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .carosel-text p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }
  .carosel-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .arrow {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  .carosel-text {
    width: 100%;
    padding: 25px;
    margin: 0px;
    justify-self: center;
  }
  .about-text {
    font-size: 14px;
  }
  .about-page-card2-box2 {
    width: 100%;
  }
  .feather-img,
  .compass-img {
    width: 300px;
    display: flex;
  }
  .feather-img:hover {
    animation: tilt-n-move-shaking 0.25s infinite;
  }
  @keyframes tilt-n-move-shaking {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(5px, 5px) rotate(5deg);
    }
    50% {
      transform: translate(0, 0) rotate(0eg);
    }
    75% {
      transform: translate(-5px, 5px) rotate(-5deg);
    }
    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }
  .about-card {
    bottom: 50px;
    left: 80px;
    font-size: 13px;
    width: 250px;
    height: 200px;
    line-height: 17px;
  }
  .about-page-card1-img {
    width: 80%;
  }
  .about-card-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .attribution-card-holder {
    flex-direction: column;
    margin-top: 0px;
  }
  .attribution-card {
    width: 300px;
    margin-bottom: 20px;
    height: 230px;
  }
  .attribution-card p {
    font-size: 12px;
  }
  .attribution-container {
    height: auto;
    padding-bottom: 100px;
  }
  .attribution-container h3 {
    font-size: 25px;
  }
  .get-in-touch h2 {
    font-size: 12px;
    font-family: Poppins;
    line-height: normal;
  }
  .get-in-touch {
    padding: 10px;
    height: 100px;
    margin-top: 0px;
  }
  .get-in-touch-btn {
    width: 40%;
    height: 40px;
  }
  .header4 {
    margin-top: 30px;
    width: 100%;
    margin-left: 0px;
  }
  .team-links {
    font-size: 10px;
  }
  .service-text p {
    font-size: 14px;
    padding: 10px;
  }
  .service-img {
    margin-bottom: 50px;
  }
  .service-img-holder {
    margin: 0px;
  }
  .row {
    padding-left: 0px;
  }
  .service-container {
    margin-bottom: 70px;
  }
  .container1 {
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .service-grid h1 {
    font-size: 18px;
  }
  .service-grid h3 {
    font-size: 12px !important ;
  }
  .service-btn {
    line-height: 10px;
    font-size: 10px;
  }
  .service-arrow {
    width: 15px;
  }
  .footer1-card-holder {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 30px;
  }
  .wealth-row {
    margin-left: 1px;
  }
  .trustkids {
    width: 70%;
  }
  .footer1-container {
    width: 100%;
    height: auto;
  }
  .footer1-card,
  .footer1-question-img {
    width: 80%;
    margin-bottom: 20px;
  }
  .footer1-bg-img {
    width: 80%;
    margin-bottom: 20px;
  }
  .footer1-bg-img img {
    width: 100%;
  }
  .dropdown-menu.show {
    width: 60%;
  }
  .footer2-cards {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer2-card2 {
    width: 100%;
    text-align: center;
    border-bottom: none;

    flex-direction: column;
  }
  .footer2-card3 {
    animation: rotateY-anim 3s linear infinite;
    margin-bottom: 50px;
  }
  @keyframes rotateY-anim {
    10% {
      transform: rotateY(0deg);
    }
    0% {
      transform: rotateY(0deg);
    }
    10% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  .footer2-card1 {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  .footer2-card1 form {
    display: flex;
  }
  .footer2-form-btn {
    bottom: 60px;
    left: 200px;
    font-size: 12px;
    height: 40px;
    margin-top: 20px;
    width: 80px;
  }
  .footer3-card1 {
    margin-bottom: 20px;
  }
  .footer3-holder {
    flex-direction: column;
    margin-bottom: 300px;
    width: 100%;
  }
  .footer3-card-holder {
    width: 100%;
    margin-top: 0px;
    font-size: 14px;
    justify-content: space-evenly;
    display: flex;
  }
  .footer3-card3 {
    margin-left: 50px;
  }
  .footer3-card-holder h5 {
    font-size: 14px;
  }
  /* aboutpage */
  .about-page-header,
  .main-MF {
    height: 100px;
  }
  .about-page-header h1,
  .main-MF h1,
  .our-services h1 {
    font-size: 20px;
  }
  .about-page {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
  }
  .about-page-card1 {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .about-page-card1-text {
    width: 80%;
    font-size: 14px;
    padding: 20px;
  }
  .about-page-card2-text p {
    font-size: 14px;
  }
  .about-page-card2-text h4 {
    font-size: 16px;
  }
  .about-page-card2-text {
    overflow: hidden;

    height: max-content;
    flex-direction: column;
  }
  .about-img {
    width: 300px;
    border-radius: 0px;
    height: 200px;
  }
  .about-page-card3 h1 {
    font-size: 18px;
  }
  .about-page-card3 {
    height: 100px;
    margin-top: 100px;
  }
  .about-page-card2-box {
    padding: 20px;
    width: 100%;
  }
  .about-page-card1-img {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-box h4 {
    font-size: 16px;
  }
  .logo-box p {
    font-size: 14px;
  }
  .footer-4 {
    display: block;
    height: auto;
  }
  .footer4-card2,
  .footer4-card3 {
    display: none;
  }
  .footer4-card1 {
    width: 70%;
  }
  .footer4-cards {
    display: block;
    height: fit-content;
    background-color: #0d2949;
    color: #ffff;
    padding: 35px;
  }
  .footer3-holder {
    margin: 10px;
  }
  .footer5-cards {
    display: none;
  }
  .social-media-icon {
    margin-top: 50px;
  }
  .accordion1 {
    display: contents;
  }
  .accordion-body {
    width: 85%;
    margin-top: 30px;
  }

  .accordion h4 {
    line-height: 40px;
  }
  .profile-img {
    width: 150px;
    height: 180px;
    border-radius: 5px;
  }
  .team-page-card2 {
    height: auto;
  }
  .team-page-card2 h2 {
    font-size: 18px !important;
  }
  .team-page-btn1 {
    width: 150px;
    height: 55px;
  }
  .team-page-btn2 {
    width: 150px;
    height: 55px;
  }
  .team-links2 {
    line-height: normal;
    font-size: 10px;
  }
  .modal-body {
    height: auto;
    margin-bottom: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .ADC-container {
    width: 70%;
    margin-top: 30px;
  }
  .service-info {
    padding: 10px;
    font-size: 12px;
    width: 200px;
    height: auto;
  }
  .service-img2 {
    width: 50%;
    height: auto;
  }
  .service-cards {
    height: auto;
    width: 100%;
  }
  .mutual-card1 {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mutual-info {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  .sub-form {
    font-size: 10px !important;
  }
  .mutual-cards h4 {
    font-size: 20px !important;
  }
  .mutual-img-holder {
    margin-left: 0px;
    padding: 0px;
  }
  .research-mutual-img-holder {
    margin-left: 0px;
    padding: 0px;
  }
  .research-mutual-img {
    padding: 0px;
    height: 250px !important;
  }
  .research-mutual-info {
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 20px !important;
  }
  .header4 {
    padding-left: 20px;
  }
  .mutual-img {
    height: 300px;
    border-radius: 5px;
  }
  .mutual-cards {
    width: 100%;
    margin: 0 auto;
  }
  /* .contactpage */

  .contact-holder {
    display: flex;
    flex-direction: column;
  }
  .contact-icon {
    width: 100%;
    text-align: right;
  }
  .contact-page-card1 {
    flex-direction: column;
  }
  .contact-page-form {
    width: 100%;
    padding: 10px;
    color: #ffff;
  }
  .contact-page-form form {
    width: 100%;
  }
  .contact-page-info {
    display: flex;
    width: 100%;
    font-size: 12px;
  }
  .contact-page-form form {
    margin: 0px;
  }
  .info-text-holder {
    width: 95%;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .about-card {
    width: 270px;
    height: 240px;
  }
  .mutual-cards h4 {
    font-size: 20px !important;
  }
  .main-MF h1 {
    font-size: 30px;
  }
  .attribution-container {
    height: 100vh;
  }
  .carosel-container {
    height: 50vh;
  }
  .mobile-nav {
    background: rgba(4, 65, 136, 0.8);
    z-index: 1;
    padding: 0 1rem 1rem;
    text-align: left;
    width: 50%;
    border-radius: 10px;
  }
  a.dropdown-toggle.nav-link {
    color: rgb(255, 255, 255);
  }
  g .mutual-card1 {
    width: 100%;
  }
  .mutual-img-holder {
    margin-left: 53px;
  }
  .footer2-card2 {
    width: 100%;
    margin-left: 30px;
  }
  .footer2-card3 {
    animation: none;
    width: 400px;
  }
  .nav-btn {
    margin-top: 20px;
    width: 35%;
  }
  a.btn.btn-primary {
    margin-top: 5px;
    width: 30%;
  }
  .about-card-holder {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .get-in-touch {
    width: 100%;
    padding: 20px;
  }
  .attribution-card {
    background-color: #ffff;
    width: 240px;
    border-radius: 5px;
    padding: 20px;
    height: 250px;
  }
  .attribution-card p {
    font-family: Poppins;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: 19px;
  }
  .attribution-card h4 {
    font-size: 16px;
  }
  .footer1-card {
    height: 300px;
    text-align: normal;
    width: 250px;
  }

  .footer1-card p {
    line-height: 17px;
    text-align: center;
    font-size: 12px;
  }
  .footer1-card h4 {
    font-size: 16px;
    line-height: 14px;
  }
  .footer1-card-holder {
    padding-left: 5px;
    padding-right: 5px;
    justify-content: space-between;
    width: 100%;
  }
  .footer1-container {
    width: 100%;
  }
  .footer2-form-btn {
    position: static;
    margin-top: 10px;
  }
  .carosel-container {
    margin-top: 100px;
  }
  .carosel-text h1 {
    font-size: 40px;
  }
  .carosel-text,
  .carosel-img {
    height: auto;
  }
  .arrow {
    width: 80%;
    height: 300px;
  }
  .about-page-card2 {
    margin-bottom: 300px;
  }
}
@media screen and (min-width: 1024px) {
  .footer2-form-btn {
    position: static;
    margin-top: 10px;
  }
  .attribution-container {
    height: 100vh;
  }
  .get-in-touch {
    width: 100%;
  }

  .service-info {
    width: 50%;
  }
}
