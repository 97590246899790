.request-container {
  display: flex;
}
.payment-overlay {
  background-color: rgba(4, 65, 136, 0.2);
  height: 540px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: #ffff;
}
.payment-card {
  background-image: url(./images/payment.png);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 540px;
  background-repeat: no-repeat;
}
.request-card {
  background-image: url(./images/request.png);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 540px;
  background-repeat: no-repeat;
}
.close-btn {
  float: right;
  color: red;
  border: none;
  background-color: inherit;
  font-weight: 800;
  margin-bottom: 30px;
}
.request-btn {
  background-color: #ffff;
  color: #044188;
  border-radius: 10px;
  width: 35%;
  height: 60px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  border: none;
}
.form-container {
  width: 100%;
  background-color: #f5f6f7;
}
.form-card {
  display: flex;
  padding: 20px;
  margin: 25px;
  flex-direction: column;
  align-items: center;
}
Form.request-form {
  width: 65%;
  padding: 10px;
  border-radius: 10px;
  border: 0.5px solid #979798;
}
label.form-label,
label {
  color: #404040;
  font-size: 14px;
  font-family: Poppins;
}
.form-select {
  background: linear-gradient(180deg, #ffffff 0%, #dfe1ec 100%);
  font-size: 14px;
}

.form-check-reverse {
  text-align: left !important;
}
.submit-btn {
  float: right;
  width: 149px;
  height: 35px;
  font-size: 14px;
  margin-top: 10px;
  font-family: Poppins;
  background-color: #044188;
  border: none;
  color: #ffff;
  border-radius: 5px;
  font-weight: 600;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
@media only screen and (max-width: 700px) {
  .request-container {
    flex-direction: column;
  }
  .request-btn {
    width: 50%;
    margin-top: 0px;
  }
  Form.request-form {
    width: 100%;
  }
}
